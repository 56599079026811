<mat-sidenav-container class="sidenav-container">

  <!-- SIDENAV -->

  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
     
      <a mat-list-item routerLink="/" (click)="drawer.close()">Home</a>
      <a mat-list-item routerLink="/metricinfo" (click)="drawer.close()">Metadata</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>

  <!-- TOP TOOLBAR-->

    <mat-toolbar>
      <button 
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">


        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="logo" routerLink="/">UMB Kosmos</span>

      <span class="fill-space"></span>
      <div *ngIf="!(isHandset$ | async)">
        <a mat-button routerLink="/metricinfo">🌠 Metadata</a> 

      </div>

      <!-- DROPDOWN MENU -->

      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      
      <mat-menu #menu="matMenu">
      </mat-menu>
    </mat-toolbar>

    <!-- TRANSCLUSION -->
    <ng-content></ng-content>

  </mat-sidenav-content>
</mat-sidenav-container>

