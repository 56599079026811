export class MetricInfo{
    constructor(
    public friendlyName: string,
    public isSupported: boolean,
    public metricName: string,
    public metricUnits: string,
    public metricGroup: string,
    public metricSource: string
    ){ }
}

export class MetricList{
    constructor(
    public friendlyName: string,
    public isSupported: boolean,
    public metricName: string,
    public metricUnits: string,
    public metricGroup: string,
    public metricSource: string
    ){ }
}