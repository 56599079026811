import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { switchMap, map } from 'rxjs/operators';
import { MetricInfo } from '../metric-info-form/metric-info.model';

@Injectable({
  providedIn: 'root'
})
export class MetricInfoService {
  constructor(private db: AngularFirestore,private afAuth: AngularFireAuth) {}

  async createMetric(data: MetricInfo, source: string) {
    const user = await this.afAuth.auth.currentUser;
    return this.db
    .collection(source)
    .add({...data,uid: user.uid});
  }

  async updateMetric(data: MetricInfo, source: string, id: string) {
    const user = await this.afAuth.auth.currentUser;
    return this.db
    .collection(source)
    .doc(id)
    .set({...data,uid: user.uid});
  }

  deleteMetric(metricName: string,source: string) {
    return this.db
    .collection(source)
    .doc(metricName) // references the metric based on Id
    .delete();
  }

  getUserInfo(){
    const user =  this.afAuth.auth.currentUser;
    return user.displayName;
  }

   /** Get all boards owned by current user */
   getSensorMetrics(sensor: string) {///
           return this.db
            .collection<MetricInfo>(sensor, ref =>
            // first you run the query you get error by fs and need to build index (just follow the link!)
            ref.where('metricSource', '==', sensor).orderBy('metricGroup')
          )
          .valueChanges({ idField: 'id' }); // additional Field: idField///////////////////////////////////////////
         
      }
    


  

}