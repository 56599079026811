// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production : false,
  firebase: {
    apiKey: "AIzaSyDtExxqP1MUPCthPJs4-5clMEC-YNOrepU",
    authDomain: "universal-monitoring-bus-dev.firebaseapp.com",
    databaseURL: "https://universal-monitoring-bus-dev.firebaseio.com",
    projectId: "universal-monitoring-bus-dev",
    storageBucket: "universal-monitoring-bus-dev.appspot.com",
    messagingSenderId: "694142850449",
    appId: "1:694142850449:web:74cdae7d1d86d1184af630",
    measurementId: "G-56J044GR83"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
