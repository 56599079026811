import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User } from './user';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private router: Router) {
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {

    const user = await this.afAuth.auth.currentUser;
    const provider = new firebase.auth.SAMLAuthProvider('saml.rackspace.com');

    const isLoggedIn = !!user;

    if (!isLoggedIn) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          console.log("Authenticated")
          // Already signed in.
          firebase.auth().getRedirectResult()
            .then((result) => {

              interface claims {
                givenname: string;
                surname: string;
                samAccountName: string;
                emailaddress: string;
                CommonName: string;
              }

              let claimStr = JSON.stringify(result.additionalUserInfo.profile)
              let claimMap: claims  = JSON.parse(claimStr)
              
              this.afAuth.auth.currentUser.updateProfile({
                displayName: claimMap.samAccountName
              })

              this.afAuth.auth.currentUser.updateEmail(claimMap.emailaddress)

              this.updateFsUserData(this.afAuth.auth.currentUser)

              //console.log(claimMap);
              // User is signed in.
              // Provider data available in result.additionalUserInfo.profile,
              // or from the user's ID token obtained from result.user.getIdToken()
              // as an object in the firebase.sign_in_attributes custom claim
              // This is also available from result.user.getIdTokenResult()
              // idTokenResult.claims.firebase.sign_in_attributes.
            }).catch((error) => {
              // Handle error.
            });

          this.router.navigate(['/']);

        } else {
          // not signed in.
          console.log("Authentication Required");
          //set auth persistence to session
          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
          .then(function() {
            firebase.auth().signInWithRedirect(provider);
          })
          .catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
          });
        }
      })
    }
    return isLoggedIn;
  }

  private updateFsUserData(user) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const data: User = {
      uid: this.afAuth.auth.currentUser.uid,
      email: this.afAuth.auth.currentUser.email,
      displayName: this.afAuth.auth.currentUser.displayName,
      roles: {
        authed: true
      }
    }
    return userRef.set(data, { merge: true })
  }

}
