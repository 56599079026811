import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from './user';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })


export class AuthService {

  user$: Observable<User>;

  constructor(private http: HttpClient,private afAuth: AngularFireAuth, private afs: AngularFirestore, private router: Router) {
    //// Get auth data, then get firestore user document || null
    this.user$ = this.afAuth.authState
        .pipe(switchMap(user => {
        if (user) {
        return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
        } else {
        return of(null)
        }
    })
    )

}


  ///// Role-based Authorization //////

  isMaasUser(user: User): boolean {
    const allowed = ['admin', 'maas']
    return this.checkAuthorization(user, allowed)
  }

  isZenossUser(user: User): boolean {
    const allowed = ['admin', 'zenoss']
    return this.checkAuthorization(user, allowed)
  }

  isScomUser(user: User): boolean {
    const allowed = ['admin', 'scom']
    return this.checkAuthorization(user, allowed)
  }

  isDximUser(user: User): boolean {
    const allowed = ['admin', 'dxim']
    return this.checkAuthorization(user, allowed)
  }

  isAdmin(user: User): boolean {
    const allowed = ['admin']
    return this.checkAuthorization(user, allowed)
  }

  async getUserData(): Promise<User> {
    return this.afs.collection('users').doc<User>(this.afAuth.auth.currentUser.uid).valueChanges().toPromise()
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false
    for (const role of allowedRoles) {
      if ( user.roles[role] ) {
        return true
      }
    }
    return false
  }

}
