import { MetricInfoService } from '../services/metric-info.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MetricInfo } from './metric-info.model';
import { Subscription } from 'rxjs';
import { AuthGuard } from '../user/auth.guard';
import { AuthService } from '../user/auth.service';
import { User } from '../user/user';

@Component({
  selector: 'app-metric-info-form',
  templateUrl: './metric-info-form.component.html',
  styleUrls: ['./metric-info-form.component.scss']
})

export class MetricInfoFormComponent implements OnInit, OnDestroy {

  zenossMetadatas: MetricInfo[];
  maasMetadatas: MetricInfo[];
  dximMetadatas: MetricInfo[];
  scomMetadatas: MetricInfo[];

  metadatas: MetricInfo[];
  metadata: MetricInfo;
  private userSub: Subscription = new Subscription();
  private sub: Subscription = new Subscription();
  user: User;

  constructor(public metricInfoService: MetricInfoService, private authService: AuthService) {

  }

  ngOnInit() {
    this.userSub = this.authService.user$.subscribe(user => {
      this.user = user
      if(this.authService.isZenossUser(this.user)){
        this.sub.add(this.metricInfoService
          .getSensorMetrics("zenoss")
          .subscribe(zm => (this.zenossMetadatas = zm)))
      }
      if(this.authService.isMaasUser(this.user)){
        this.sub.add(this.metricInfoService
          .getSensorMetrics("maas")
          .subscribe(sam => (this.maasMetadatas = sam)))
      }
      if(this.authService.isDximUser(this.user)){
        this.sub.add(this.metricInfoService
          .getSensorMetrics("dxim")
          .subscribe(dm => (this.dximMetadatas = dm)))
      }
      if(this.authService.isScomUser(this.user)){
        this.sub.add(this.metricInfoService
          .getSensorMetrics("scom")
          .subscribe(scm => (this.scomMetadatas = scm)))
      }
    })

    this.metadata = new MetricInfo('',false,'', '', '', '');
  }

  un: string = this.metricInfoService.getUserInfo()

  groups: string[] = ['cpu', 'disc', 'memory', 'network', 'database', 'os', 'hardware'];
  sourceSystems: string[] = ['dxim', 'maas', 'scom', 'zenoss'];
  displayedColumns: string[] = ['metricName','friendlyName','isSupported','metricUnits', 'metricGroup', 'metricSource','metricId'];

  submitted = false
  keyid: string;
  deleteId: string;
  deleteSrc: string;

  onSubmit() {
    this.submitted = true;
  }

  newMetadata() {
    this.metadata = new MetricInfo('',false,'', '', '', '');
  }

  handleDelete(srcSys: string) {
    this.metricInfoService.deleteMetric(this.deleteId,srcSys);
  }

  handleCreate(srcSys: string): void {
    this.keyid = this.metadata.metricSource + "_" + this.metadata.metricName
    this.metricInfoService.updateMetric({
      friendlyName: this.metadata.friendlyName,
      isSupported: this.metadata.isSupported,
      metricName: this.metadata.metricName,
      metricUnits: this.metadata.metricUnits,
      metricGroup: this.metadata.metricGroup,
      metricSource: this.metadata.metricSource
    },srcSys,this.keyid);
  };

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.userSub.unsubscribe();
  }
}
