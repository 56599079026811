import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { AuthGuard } from './user/auth.guard';
import { MetricInfoFormComponent } from './metric-info-form/metric-info-form.component';


const routes: Routes = [
  { path: '', component: HomePageComponent, canActivate: [AuthGuard]}, ///this is the thing to make a new componant and to change
  { path: 'metricinfo', component: MetricInfoFormComponent, canActivate: [AuthGuard]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}