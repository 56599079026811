<div style="text-align:center">
    <h1>
      Welcome to UMB Kosmos
    </h1>
    <h2>
      Universal Monitoring Bus
    </h2>
    <h3>
      Kafka Operations, Security, and Metadata Orchestration System
    </h3>
    <img src="/assets/segment-support-shield.png" />
  </div>
