<div>
  <div id="container">
    <h1>Metric Information Metadata</h1>
    </div>
    <br>

<div style="width: 100%;">
  <div style="width: 30%; float: left; text-align: center;">
    <h3>You are signed in as {{un}}</h3>
    <br>
    <div [hidden]="submitted">
      <h3>Update Metric:</h3>
      <form (ngSubmit)="onSubmit()" #submitMetric="ngForm">
          <div class="form-group">
              <label for="metricName">Metric Name: </label>
              <input type="text" class="form-control" id="metricName" required [(ngModel)]="metadata.metricName"
                  name="metricName" #metricName="ngModel">
              <div [hidden]="metricName.valid || metricName.pristine" class="alert alert-danger">
                  Name is required
              </div>
          </div>
          <div class="form-group">
            <label for="friendlyName">Friendly Name: </label>
            <input type="text" class="form-control" id="friendlyName" required [(ngModel)]="metadata.friendlyName"
                name="friendlyName" #friendlyName="ngModel">
            <div [hidden]="friendlyName.valid || friendlyName.pristine" class="alert alert-danger">
                Friendly Name is required
            </div>
        </div>
              <div class="form-group">
                <label for="isSupported">Supported?: </label>
                <input type="checkbox" class="form-control" id="isSupported" required [(ngModel)]="metadata.isSupported"
                    name="isSupported" #isSupported="ngModel">
                <div [hidden]="isSupported.valid || isSupported.pristine" class="alert alert-danger">
                    This is required.
                </div>
            </div>
          <div class="form-group">
              <label for="metricUnits">Metric Units: </label>
              <input type="text" class="form-control" id="metricUnits" required [(ngModel)]="metadata.metricUnits"
                  name="metricUnits" #metricUnits="ngModel">
              <div [hidden]="metricUnits.valid || metricUnits.pristine" class="alert alert-danger">
                  Units are required
              </div>
          </div>

          <div class="form-group">
              <label for="metricGroup">Metric Group: </label>
              <select class="form-control" id="metricGroup" required [(ngModel)]="metadata.metricGroup"
                  name="metricGroup" #metricGroup="ngModel">
                  <option *ngFor="let group of groups" [value]="group">{{group}}</option>
              </select>
              <div [hidden]="metricGroup.valid || metricGroup.pristine" class="alert alert-danger">
                  Group is required
              </div>
          </div>

          <div class="form-group">
              <label for="sourceSystem">Source System: </label>
              <select class="form-control" id="sourceSystem" required [(ngModel)]="metadata.metricSource"
                  name="sourceSystem" #sourceSystem="ngModel">
                  <option *ngFor="let source of sourceSystems" [value]="source">{{source}}</option>
              </select>
              <div [hidden]="sourceSystem.valid || sourceSystem.pristine" class="alert alert-danger">
                  Source System is required
              </div>
          </div>

          <button mat-raised-button type="submit" class="btn btn-success" (click)="handleCreate(metadata.metricSource);">Submit</button>
          <button mat-raised-button type="button" class="btn btn-default" (click)="newMetadata(); submitMetric.reset()">Refresh</button>
      </form>
  </div>

  <div [hidden]="!submitted">
      <h2>You submitted the following:</h2>
      <div class="row">
          <div class="col-xs-3">Name: {{ metadata.metricName }}</div>
      </div>
      <div class="row">
        <div class="col-xs-3">Friendly Name: {{ metadata.friendlyName }}</div>
      </div>
      <div class="row">
      <div class="col-xs-3">Supported: {{ metadata.isSupported }}</div>
      </div>
      <div class="row">
          <div class="col-xs-3">Units: {{ metadata.metricUnits }}</div>
      </div>
      <div class="row">
          <div class="col-xs-3">Group: {{ metadata.metricGroup}}</div>
      </div>
      <div class="row">
          <div class="col-xs-3">Source: {{ metadata.metricSource }}</div>
      </div>
      <br>
      <button mat-raised-button class="btn btn-primary" (click)="submitted=false">Submit Another</button>
  </div>

<div>
  <div class="form-group">
      <h3>Delete Metric:</h3>
      <label for="deleteMetric">Metric ID: </label>
      <input type="text" class="form-control" id="deleteMetric" [(ngModel)]="deleteId"
      name="deleteMetric" #deleteMetric="ngModel">
  </div>
  <div class="form-group">
      <label for="sourceSystem">Source System: </label>
      <select class="form-control" id="sourceSystem" required [(ngModel)]="deleteSrc"
          name="sourceSystem" #sourceSystem="ngModel">
          <option *ngFor="let source of sourceSystems" [value]="source">{{source}}</option>
      </select>
      <div [hidden]="sourceSystem.valid || sourceSystem.pristine" class="alert alert-danger">
          Source System is required
      </div>
  </div>
  <button mat-raised-button type="submit" class="btn btn-danger" (click)="handleDelete(deleteSrc); deleteMetric.reset()">Delete Metric</button>
</div>
  </div>

  <div style="margin-left: 30%; text-align: center;">
    Check the Missing List for your system here:
    <a href="https://support.dev.monplat.rackspace.net/d/lhkk6X-Mz/metrics?orgId=1&from=now-15m&to=now"
    target="_blank" rel="noopener"><img border="0" alt="Grafana" src="/assets/grafana.png" width="50" height="50">
    </a>
  </div>
</div>

<div id="container">
<mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
  <mat-tab label="MaaS"><div>
    <table mat-table [dataSource]="maasMetadatas" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
        <!-- Position Column -->
        <ng-container matColumnDef="friendlyName">
          <th mat-header-cell *matHeaderCellDef> Friendly Name </th>
          <td mat-cell *matCellDef="let md"> {{md.friendlyName}} </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="isSupported">
         <th mat-header-cell *matHeaderCellDef> Supported? </th>
         <td mat-cell *matCellDef="let md"> {{md.isSupported}} </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="metricId">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let md"> {{md.id}} </td>
          </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="metricName">
          <th mat-header-cell *matHeaderCellDef> Raw Name </th>
          <td mat-cell *matCellDef="let md"> {{md.metricName}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="metricUnits">
          <th mat-header-cell *matHeaderCellDef> Units </th>
          <td mat-cell *matCellDef="let md"> {{md.metricUnits}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="metricGroup">
          <th mat-header-cell *matHeaderCellDef> Group </th>
          <td mat-cell *matCellDef="let md"> {{md.metricGroup}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="metricSource">
          <th mat-header-cell *matHeaderCellDef> Source </th>
          <td mat-cell *matCellDef="let md"> {{md.metricSource}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div></mat-tab>
  <mat-tab label="Zenoss"><div>
    <table mat-table [dataSource]="zenossMetadatas" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
        <!-- Position Column -->
        <ng-container matColumnDef="friendlyName">
          <th mat-header-cell *matHeaderCellDef> Friendly Name </th>
          <td mat-cell *matCellDef="let md"> {{md.friendlyName}} </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="isSupported">
         <th mat-header-cell *matHeaderCellDef> Supported? </th>
         <td mat-cell *matCellDef="let md"> {{md.isSupported}} </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="metricId">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let md"> {{md.id}} </td>
          </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="metricName">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let md"> {{md.metricName}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="metricUnits">
          <th mat-header-cell *matHeaderCellDef> Units </th>
          <td mat-cell *matCellDef="let md"> {{md.metricUnits}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="metricGroup">
          <th mat-header-cell *matHeaderCellDef> Group </th>
          <td mat-cell *matCellDef="let md"> {{md.metricGroup}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="metricSource">
          <th mat-header-cell *matHeaderCellDef> Source </th>
          <td mat-cell *matCellDef="let md"> {{md.metricSource}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div></mat-tab>
  <mat-tab label="DXIM"><div>
    <table mat-table [dataSource]="dximMetadatas" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
        <!-- Position Column -->
        <ng-container matColumnDef="friendlyName">
          <th mat-header-cell *matHeaderCellDef> Friendly Name </th>
          <td mat-cell *matCellDef="let md"> {{md.friendlyName}} </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="isSupported">
         <th mat-header-cell *matHeaderCellDef> Supported? </th>
         <td mat-cell *matCellDef="let md"> {{md.isSupported}} </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="metricId">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let md"> {{md.id}} </td>
          </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="metricName">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let md"> {{md.metricName}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="metricUnits">
          <th mat-header-cell *matHeaderCellDef> Units </th>
          <td mat-cell *matCellDef="let md"> {{md.metricUnits}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="metricGroup">
          <th mat-header-cell *matHeaderCellDef> Group </th>
          <td mat-cell *matCellDef="let md"> {{md.metricGroup}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="metricSource">
          <th mat-header-cell *matHeaderCellDef> Source </th>
          <td mat-cell *matCellDef="let md"> {{md.metricSource}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div></mat-tab>
    <mat-tab label="SCOM"><div>
      <table mat-table [dataSource]="scomMetadatas" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
        <!-- Position Column -->
        <ng-container matColumnDef="friendlyName">
          <th mat-header-cell *matHeaderCellDef> Friendly Name </th>
          <td mat-cell *matCellDef="let md"> {{md.friendlyName}} </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="isSupported">
         <th mat-header-cell *matHeaderCellDef> Supported? </th>
         <td mat-cell *matCellDef="let md"> {{md.isSupported}} </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="metricId">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let md"> {{md.id}} </td>
          </ng-container>

          <!-- Position Column -->
          <ng-container matColumnDef="metricName">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let md"> {{md.metricName}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="metricUnits">
            <th mat-header-cell *matHeaderCellDef> Units </th>
            <td mat-cell *matCellDef="let md"> {{md.metricUnits}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="metricGroup">
            <th mat-header-cell *matHeaderCellDef> Group </th>
            <td mat-cell *matCellDef="let md"> {{md.metricGroup}} </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="metricSource">
            <th mat-header-cell *matHeaderCellDef> Source </th>
            <td mat-cell *matCellDef="let md"> {{md.metricSource}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div></mat-tab>
</mat-tab-group>
</div>
